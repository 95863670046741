.input {
  width: 100%;
  height: 40px;

  &:focus {
    @extend %inputFocus;
  }
}

%input {
  width: 100%;
  height: 40px;

  &:focus {
    @extend %inputFocus;
  }
}

.inputFocus {
  box-shadow: 0 0 0 2px #66339927;
}

%inputFocus {
  box-shadow: 0 0 0 2px #66339927;
}

.inputWithFocus {
  @extend %input, %inputFocus;
}
